import {SurveyComponent} from "../../../../components/survey/survey_component";
import env from "../../../../env";
import {expressions} from "../../../../survey/validators";

const required = env.ENDPOINT !== "local";

export const beneficiary_credicapital = (clientUrlForCatalog) =>
  new SurveyComponent({
    title: {
      en: "Beneficiary information",
      "es-MX": "Datos del beneficiario",
    },
    elements: [
      {
        title: {
          en: "Please indicate the details of at least one beneficiary.",
          "es-MX": "Por favor indicar los datos de al menos un beneficiario.",
        },
        name: "custom_jsonfield_1",
        valueName: "custom_jsonfield_1",
        type: "matrixdynamic",
        minRowCount: 1,
        maxRowCount: 3,
        columnLayout: "vertical",
        requiredErrorText: {
          en: "100% entre los beneficiarios",
          "es-MX": "100% entre los beneficiarios",
        },
        rowCount: 1,
        addRowLocation: "bottom",
        validators: [
          {
            type: "expression",
            expression: "sumInArray({custom_jsonfield_1}, 'porcentaje') == 100",
            text: {
              en: "The total percentage should be 100%",
              "es-MX": "El porcentaje total debe ser 100%",
            },
          },
        ],
        isRequired: true,
        addRowText: {
          en: "Add new beneficiary",
          "es-MX": "Añadir beneficiario",
        },
        columns: [
          {
            title: { en: "Full name", "es-MX": "Nombre completo " },
            name: "nombre_completo",
            isRequired: required,
            cellType: "text",
            inputType: "string",
          },
          {
            title: {
              en: "Relationship",
              "es-MX": "Parentesco",
            },
            cellType: "dropdown",
            isRequired: required,
            name: "parentesco",
            choices: [
              {
                value: "padre_madre",
                text: { en: "Father/Mother", "es-MX": "Padre/Madre" },
              },
              {
                value: "cunado",
                text: { en: "Brother in law", "es-MX": "Cuñado" },
              },
              { value: "esposo", text: { en: "Spouse", "es-MX": "Esposo(a)" } },
              {
                value: "hermano",
                text: { en: "Sibling", "es-MX": "Hermano(a)" },
              },
              { value: "hijo", text: { en: "Son", "es-MX": "Hijo(a)" } },
              {
                value: "no_aplica",
                text: { en: "Does not apply", "es-MX": "No aplica" },
              },
              { value: "otro", text: { en: "Other", "es-MX": "Otro" } },
            ],
          },
          {
            title: {
              en: "Upload INE document *",
              "es-MX": "Subir documento de INE*",
            },
            name: "subir_documento_ine",
            isRequired: required,
            cellType: "file_uploader",
          },
          {
            title: { en: "Phone number", "es-MX": "Número de teléfono " },
            name: "numero_de_telefono",
            cellType: "phone",
            validators: [
              expressions.phone_en("numero_de_telefono"),
              expressions.phone_length("numero_de_telefono"),
            ],
            isRequired: required,
          },
          {
            title: { en: "Date of birth", "es-MX": "Fecha de nacimiento" },
            name: "fecha_de_nacimiento",
            cellType: "text",
            inputType: "date",
            isRequired: required,
            validators: [
              {
                type: "expression",
                text: {
                  en: "Please, enter a valid date",
                  "es-MX": "Por favor, ingrese una fecha válida",
                },
                expression:
                "(age({row.fecha_de_nacimiento}) >= 18) && (age({row.fecha_de_nacimiento}) <= 74)",
              },
            ],
          },
          {
            title: { en: "State", "es-MX": "Estado" },
            cellType: "dropdown",
            isRequired: required,
            name: "estado",
            renderAs: "select2",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/entidadfederativa/entidades-federativa`,
              titleName: "Descripcion",
              valueName: "Descripcion",
              attachOriginalItems: true,
            },
          },
          {cellType: "text", name: "idEntidadFederativa", visible: false, title: " "},
          {
            title: { en: "Municipality", "es-MX": "Municipio" },
            cellType: "dropdown",
            isRequired: required,
            name: "municipio",
            renderAs: "select2",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/municipio/id-municipio-by-entidadfederativa?idEntidadFederativa={row.idEntidadFederativa}`,
              titleName: "Descripcion",
              valueName: "Descripcion",
              attachOriginalItems: true,
            },
          },
          {cellType: "text", name: "idMunicipio", visible: false, title: " "},
          {
            cellType: "dropdown",
            name: "colonia",
            title: { en: "Suburb", "es-MX": "Colonia" },
            renderAs: "select2",
            isRequired: required,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/colonia/colonias-municipio?idMunicipio={row.idMunicipio}&idEntidadFederativa={row.idEntidadFederativa}`,
              titleName: "Descripcion",
              valueName: "Descripcion",
              attachOriginalItems: true,
            },
          },
          {cellType: "text", name: "idColonia", visible: false, title: " "},
          {
            title: {
              en: "Postal Code",
              "es-MX": "Código postal"
            },
            cellType: "text",
            isRequired: required,
            name: "codigo_postal",
            readOnly: true,
          },
          {
            title: { en: "Address", "es-MX": "Domicilio" },
            cellType: "text",
            name: "domicilio",
            isRequired: required,
          },
          {
            title: { en: "Percentage", "es-MX": "Porcentaje" },
            cellType: "text",
            inputType: "number",
            name: "porcentaje",
          },
        ],
      },
    ],
  });
